import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import ReplyIcon from "@mui/icons-material/Reply";
import CV from "../assets/CV.pdf";
import profile from "../assets/profile.jpg";

const Home = () => (
  <section id="home" className="pt-16 max-w-lg" data-aos="fade-right">
    <img
      src={profile}
      alt="profile"
      className="h-20 rounded-full cursor-pointer"
      style={{ pointerEvents: 'none' }}
    />
    <h1 className="text-3xl font-bold mb-4 mt-4">
      Fullstack Developer and Geospatial Enthusiast.
    </h1>
    <p className="text-base">
      I'm Sansan, a fullstack developer and geospatial enthusiast based in
      Jakarta City.
    </p>
    {/* <a
      href={CV} // Replace with the actual path to your CV
      download
      className="inline-flex items-center px-3 py-2 mt-3 mr-2 bg-gray-800 text-white font-semibold rounded-md shadow-lg hover:bg-gray-700 transition-colors"
    >
      <ReplyIcon
        style={{ transform: "rotate(-90deg)" }}
        className="mr-2 border-l-4 border-gray-300 rounded-sm"
      />
      CV
    </a> */}
    <div className="flex space-x-4 mt-4">
      <a
        href="https://github.com/sansantric"
        target="_blank"
        rel="noopener noreferrer"
        className="text-gray-600 dark:text-white hover:text-gray-500 dark:hover:text-gray-300"
      >
        <GitHubIcon />
      </a>
      <a
        href="https://www.linkedin.com/in/sansan-tri-cahyana-624bb7298/"
        target="_blank"
        rel="noopener noreferrer"
        className="text-gray-600 dark:text-white hover:text-gray-500 dark:hover:text-gray-300"
      >
        <LinkedInIcon />
      </a>
      <a
        href="https://instagram.com/sansantric"
        target="_blank"
        rel="noopener noreferrer"
        className="text-gray-600 dark:text-white hover:text-gray-500 dark:hover:text-gray-300"
      >
        <InstagramIcon />
      </a>
      <a
        href="https://twitter.com/sansantric"
        target="_blank"
        rel="noopener noreferrer"
        className="text-gray-600 dark:text-white hover:text-gray-500 dark:hover:text-gray-300"
      >
        <XIcon />
      </a>
    </div>
  </section>
);

export default Home;
