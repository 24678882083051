import { useState, useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import logo from "./assets/logo.png";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import Home from "./components/Home";
import About from "./components/About";
import Projects from "./components/Projects";
import Contact from "./components/Contact";

const App = () => {
  const [darkMode, setDarkMode] = useState(true);
  const [showNavbar, setShowNavbar] = useState(true);
  const [activeSection, setActiveSection] = useState("home");
  const [isOpen, setIsOpen] = useState(false);
  const overlayRef = useRef(null);
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      // easing: "ease-in-out", // Animation easing
      once: false, // Whether animation should happen only once
    });
  }, []);
  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling
    });
  };
  const scrollToSection = (section) => {
    setActiveSection(section);
    document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  };
  // Set body background and text color based on darkMode state
  useEffect(() => {
    if (darkMode) {
      document.body.style.backgroundColor = "#121214"; // Dark background color
      document.body.style.color = "#F7F7F8"; // Dark text color
    } else {
      document.body.style.backgroundColor = "white"; // Light background color
      document.body.style.color = "#3A1078"; // Light text color
    }
  }, [darkMode]);
  //handle scroll
  useEffect(() => {
    let lastScrollTop = 0;
    const handleScroll = () => {
      AOS.refresh();
      const currentScroll = window.scrollY;
      const sections = document.querySelectorAll("section");
      let currentSection = "";
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;

        if (window.scrollY >= sectionTop - sectionHeight / 3) {
          currentSection = section.getAttribute("id");
        }
      });

      setActiveSection(currentSection);
      if (currentScroll > lastScrollTop) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }
      lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Close the overlay if the click is outside of it
  const handleOverlayClick = (e) => {
    if (overlayRef.current && !overlayRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside the overlay
    document.addEventListener("mousedown", handleOverlayClick);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClick);
    };
  }, []);
  return (
    <div
      className={`min-h-screen shadow-white ${
        darkMode ? "bg-darkBg text-darkText" : "bg-lightBg text-lightText"
      }`}
    >
      <div className="relative">
        <nav
          data-aos="fade-down"
          className={`sticky top-0 z-10 w-full transition-transform duration-300 ${
            showNavbar ? "translate-y-0" : "-translate-y-full"
          } ${
            darkMode
              ? "bg-transparent text-darkText"
              : "bg-transparent text-lightText"
          } pt-5`}
        >
          <div className="container mx-auto flex justify-between items-center pl-8 pr-8 md:pl-16 md:pr-16">
            <button onClick={() => scrollToTop()}>
              <img src={logo} alt="Sansan Logo" className="h-10 rounded-md" />
            </button>
            <div className="hidden md:flex space-x-1 bg-[#232326] rounded-full pl-1 pr-1">
              <button
                onClick={() => scrollToTop()}
                className={`inline-flex items-center px-4 py-2 rounded-full hover:text-[#63b9db] ${
                  activeSection === "home" ? "font-semibold text-[#63b9db]" : ""
                }`}
              >
                Home
              </button>
              <button
                onClick={() => scrollToSection("about")}
                className={`inline-flex items-center px-4 py-2 rounded-full hover:text-[#63b9db] ${
                  activeSection === "about"
                    ? "font-semibold text-[#63b9db]"
                    : ""
                }`}
              >
                About
              </button>
              <button
                onClick={() => scrollToSection("projects")}
                className={`inline-flex items-center px-4 py-2 rounded-full hover:text-[#63b9db] ${
                  activeSection === "projects"
                    ? "font-semibold text-[#63b9db]"
                    : ""
                }`}
              >
                Projects
              </button>
              <button
                onClick={() => scrollToSection("contact")}
                className={`inline-flex items-center px-4 py-2 rounded-full hover:text-[#63b9db] ${
                  activeSection === "contact"
                    ? "font-semibold text-[#63b9db]"
                    : ""
                }`}
              >
                Contact
              </button>
            </div>
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="md:hidden text-xl"
            >
              {isOpen ? (
                <CloseIcon />
              ) : (
                <MenuIcon
                  fontSize="large"
                  sx={{
                    backgroundColor: "#18181b",
                    borderRadius: "10%",
                  }}
                />
              )}
            </button>
          </div>
        </nav>

        {/* Mobile Menu Overlay */}
        <div
          className={`fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm transition-opacity duration-300 ${
            isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
          } md:hidden z-40`}
        >
          <div
            className={`fixed top-0 right-0 dark:bg-gray-800 transition-transform duration-300 ${
              isOpen ? "translate-y-0" : "-translate-y-full"
            } w-full max-w-full p-6`}
            ref={overlayRef}
          >
            <div className="flex flex-col items-start p-4 space-y-2 bg-[#232326] rounded-3xl border border-[#555]">
              <button
                onClick={() => setIsOpen(false)}
                className="text-darkText dark:text-white text-2xl absolute top-8 right-10"
              >
                <CloseIcon fontSize="medium" />
              </button>
              <button
                onClick={() => {
                  scrollToTop();
                  setIsOpen(false);
                }}
                className={`dark:text-white text-base border-b border-[#444] w-full text-left p-1 ${
                  activeSection === "home"
                    ? "font-semibold text-[#63b9db]"
                    : ""
                }`}
              >
                Home
              </button>
              <button
                onClick={() => {
                  scrollToSection("about");
                  setIsOpen(false);
                }}
                className={`dark:text-white text-base border-b border-[#444] w-full text-left p-1 ${
                  activeSection === "about"
                    ? "font-semibold text-[#63b9db]"
                    : ""
                }`}
              >
                About
              </button>
              <button
                onClick={() => {
                  scrollToSection("projects");
                  setIsOpen(false);
                }}
                className={`dark:text-white text-base border-b border-[#444] w-full text-left p-1 ${
                  activeSection === "projects"
                    ? "font-semibold text-[#63b9db]"
                    : ""
                }`}
              >
                Projects
              </button>
              <button
                onClick={() => {
                  scrollToSection("contact");
                  setIsOpen(false);
                }}
                className={`dark:text-white text-base w-full text-left p-1 ${
                  activeSection === "contact"
                    ? "font-semibold text-[#63b9db]"
                    : ""
                }`}
              >
                Contact
              </button>
            </div>
          </div>
        </div>

        <div className="pl-4 pr-4 md:pl-20 md:pr-20">
          <div className="container mx-auto px-4">
            <Home />
            <About />
            <Projects />
            <Contact />
          </div>
        </div>
      </div>

      <footer
        className={`pt-4 pb-4 ${
          darkMode ? "bg-darkBg text-darkText" : "bg-lightBg text-lightText"
        } border-t-2 border-[#232326]`}
      >
        <div className="container mx-auto text-center text-sm">
          <p>&copy; {new Date().getFullYear()} Sansan. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default App;
