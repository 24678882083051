import React, { useEffect, useState } from "react";
import pln1 from "../assets/pln1.png";
import pln2 from "../assets/pln2.png";
import artgis1 from "../assets/artgis1.png";
import artgis2 from "../assets/artgis2.png";
import bri1 from "../assets/bri1.png";
import bri2 from "../assets/bri2.png";

import CV from "../assets/CV.pdf";

import ReplyIcon from "@mui/icons-material/Reply";

const CarouselItem = ({ images, intervalTimes }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, intervalTimes); // Change slide every 3 seconds

    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    <div className="relative w-full overflow-hidden">
      <div
        className="carousel-inner flex transition-transform duration-500"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {images.map((src, index) => (
          <div key={index} className="carousel-item flex-shrink-0 w-full">
            <img
              src={src}
              alt={`Image ${index + 1}`}
              className="w-full rounded-md"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const ProjectsList = () => {
  const artgis = [artgis1, artgis2];
  const pln = [pln1, pln2];
  const bri = [bri1, bri2];
  return (
    <section id="projects" className="pt-20">
      <h1 className="text-3xl font-bold mb-8" data-aos="fade-up">
        Recent Projects
      </h1>
      <ul>
        <li
          className="mb-8 flex flex-col md:flex-row items-start"
          data-aos="fade-up"
        >
          <div className="w-full md:w-1/2 md:mb-0 m-2">
            <CarouselItem images={artgis} intervalTimes={2500} />
          </div>
          <div className="w-full md:w-1/2 m-2">
            <h2 className="text-xl font-semibold mb-2">Artgis Web</h2>
            <h2 className="text-base font-semibold mb-2">
              May 2024 - Jul 2024
            </h2>
            <p className="text-base">
              Developed a comprehensive web-based GIS solution using ReactJS and
              ExpressJS, improving geospatial data management and analysis.
            </p>

            <a
              href="https://www.artgis.sansantric.id/"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-2 pr-4 py-2 mt-4 bg-gray-800 text-white font-semibold rounded-md shadow-lg hover:bg-gray-700 transition-colors"
            >
              <ReplyIcon
                style={{ transform: "rotate(0deg) scaleX(-1)" }}
                className="mr-1"
              />
              Visit
            </a>
          </div>
        </li>
        <li
          className="mb-8 flex flex-col md:flex-row items-start"
          data-aos="fade-up"
        >
          <div className="w-full md:w-1/2 md:mb-0 m-2">
            <CarouselItem images={pln} intervalTimes={2900} />
          </div>
          <div className="w-full md:w-1/2 m-2">
            <h2 className="text-xl font-semibold mb-2">
              Sistem Informasi Laporan Management (SILM)
            </h2>
            <h2 className="text-base font-semibold mb-2">
              Feb 2020 - Feb 2024
            </h2>
            <p className="text-base">
              Developed a web-based application using ASP.NET to automate and
              streamline reporting processes. Enhanced reporting efficiency and
              accuracy.
            </p>
            <a
              href="https://silm.pln.co.id"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-2 pr-4 py-2 mt-4 bg-gray-800 text-white font-semibold rounded-md shadow-lg hover:bg-gray-700 transition-colors"
            >
              <ReplyIcon
                style={{ transform: "rotate(0deg) scaleX(-1)" }}
                className="mr-1"
              />
              Visit
            </a>
          </div>
        </li>
        <li
          className="mb-8 flex flex-col md:flex-row items-start"
          data-aos="fade-up"
        >
          <div className="w-full md:w-1/2 md:mb-0 m-2">
            <CarouselItem images={bri} intervalTimes={3400} />
          </div>
          <div className="w-full md:w-1/2 m-2">
            <h2 className="text-xl font-semibold mb-2">
              New Delivery System (NDS)
            </h2>
            <h2 className="text-base font-semibold mb-2">
              Aug 2022 - Nov 2023
            </h2>
            <p className="text-base">
              Developed a transaction application using VueJS, designed for
              branch office units at PT. Bank BRI.
            </p>
          </div>
        </li>
      </ul>
      {/* <div className="p-2">
        <p className="text-base font-semibold">
          Download My CV for more details :
        </p>
        <a
          href={CV} // Replace with the actual path to your CV
          download
          className="inline-flex items-center px-5 py-2 mt-1 bg-gray-800 text-white font-semibold rounded-md shadow-lg hover:bg-gray-700 transition-colors"
        >
          <ReplyIcon
            style={{ transform: "rotate(-90deg)" }}
            className="mr-2 border-l-4 border-gray-300 rounded-sm"
          />
          CV
        </a>
      </div> */}
    </section>
  );
};

export default ProjectsList;
