import React, { useState } from "react";
import emailjs from "emailjs-com";
import SendIcon from '@mui/icons-material/Send';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm("service_s8ykpbw", "template_e5r9n38", e.target, "VAm_H2WGVxOaQ7f7V")
      .then(
        (result) => {
          setStatus("Success! Your message has been sent.");
          setFormData({ name: "", email: "", message: "" }); // Clear form
        },
        (error) => {
          setStatus("Error: Could not send the message.");
        }
      );
  };

  return (
    <section id="contact" className="pt-20 pb-20" data-aos="fade-up">
      <h1 className="text-3xl font-bold mb-4 text-center">Contact</h1>
      <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
        <div className="mb-4">
          <label htmlFor="name" className="block text-md font-semibold mb-2">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded text-black"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block text-md font-semibold mb-2">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded text-black"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="message" className="block text-md font-semibold mb-2">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            rows="4"
            className="w-full px-3 py-2 border border-gray-300 rounded text-black"
          />
        </div>
        <div className="flex justify-center">
          <button
            type="submit"
            className="px-4 py-2 bg-gray-800 text-white hover:bg-gray-700 transition-colors font-semibold rounded-md"
          >
            Send
            <SendIcon className="ml-2 mb-2" sx={{ transform: "rotate(-35deg)"}} />
          </button>
        </div>
        {status && <p className="mt-4 text-lg text-center">{status}</p>}
      </form>
    </section>
  );
};

export default ContactForm;
