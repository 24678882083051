import React, { useEffect, useRef, useState } from "react";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";

import ReplyIcon from "@mui/icons-material/Reply";

import CV from "../assets/CV.pdf";
import code from "../assets/code.png";
import mis from "../assets/mis.png";
import satkom from "../assets/satkom.png";
import gms from "../assets/gms.jpg";

const ProgressBar = ({ width }) => {
  const [progressWidth, setProgressWidth] = useState("0%");
  const progressBarRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setProgressWidth(width);
          }
        });
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    if (progressBarRef.current) {
      observer.observe(progressBarRef.current);
    }

    return () => {
      if (progressBarRef.current) {
        observer.unobserve(progressBarRef.current);
      }
    };
  }, [width]);

  return (
    <div
      className="w-2/4 bg-gray-200 rounded-full h-2 relative"
      ref={progressBarRef}
    >
      <div
        className="bg-[#2645e0] h-full rounded-full border border-[#fff] transition-all duration-1000 ease-in-out"
        style={{ width: progressWidth }}
      ></div>
    </div>
  );
};

const About = () => (
  <section id="about" className="pt-20" data-aos="fade-up">
    <h1 className="text-3xl font-bold mb-4 text-right">About</h1>
    {/* <div className="flex flex-col md:flex-row items-center">
      <img
        src={code}
        alt="Sansan"
        className="w-full md:w-1/2 h-auto object-cover rounded-3xl mb-4 md:mb-0 transform rotate-[5deg] origin-top-left"
      />
      <div className="md:w-1/2 md:pl-8 mt-10 md:mt-0">
        <p className="text-base">
          Hi, I'm Sansan, a Fullstack Developer based in Jakarta City. With a
          strong foundation in both front-end and back-end technologies, I
          specialize in crafting dynamic and user-friendly web applications. My
          expertise lies in creating intuitive interfaces using React and Redux,
          and efficiently managing data fetching, authentication systems, and
          routing. I am passionate about delivering high-quality, impactful
          solutions that enhance user experiences. My commitment to detail and
          continuous learning drives me to innovate and produce web solutions
          that make a difference.
        </p>
      </div>
    </div> */}

    <div className="flex flex-col md:flex-row">
      <div className="w-full md:w-3/5">
        <p className="text-base text-justify">
          Hi, I'm Sansan, a Fullstack Developer based in Jakarta City. With a
          strong foundation in both front-end and back-end technologies, I
          specialize in crafting dynamic and user-friendly web applications. My
          expertise lies in creating intuitive interfaces using React and Redux,
          and efficiently managing data fetching, authentication systems, and
          routing. I am passionate about delivering high-quality, impactful
          solutions that enhance user experiences. My commitment to detail and
          continuous learning drives me to innovate and produce web solutions
          that make a difference.
        </p>
      </div>
      <div className="w-full md:w-2/5 md:pl-8 md:pt-0 pt-6">
        <div className="w-full shadow-white rounded-2xl p-4">
          <div className="flex flex column items-center pb-2">
            <BusinessCenterIcon className="text-gray-500" fontSize="large" />
            <p className="font-semibold pl-4">Work</p>
          </div>
          <ul>
            <li className="flex flex-row text-sm pt-4">
              <div className="w-1/6">
                <img
                  src={mis}
                  alt="mis"
                  className="object-cover h-9 rounded-full"
                />
              </div>
              <div className="w-3/6">
                <p className="font-semibold">Software Developer</p>
                <p className="">PT Mitra Infosarana</p>
              </div>
              <div className="w-2/6 text-right">
                <p className="">2020 - 2024</p>
              </div>
            </li>
            <li className="flex flex-row text-sm pt-4">
              <div className="w-1/6">
                <img
                  src={satkom}
                  alt="satkom"
                  className="object-cover h-9 rounded-full"
                />
              </div>
              <div className="w-3/6">
                <p className="font-semibold">Frontend Developer</p>
                <p className="">PT Satkomindo Mediyasa</p>
              </div>
              <div className="w-2/6 text-right">
                <p className="">2022 - 2023</p>
              </div>
            </li>
            <li className="flex flex-row text-sm pt-4">
              <div className="w-1/6">
                <img
                  src={gms}
                  alt="gms"
                  className="object-cover h-9 rounded-full"
                />
              </div>
              <div className="w-3/6">
                <p className="font-semibold">GIS Operator</p>
                <p className="">PT Geocentral</p>
              </div>
              <div className="w-2/6 text-right">
                <p className="">2017 - 2019</p>
              </div>
            </li>
            <li className="flex flex-row text-sm pt-4">
              <a
                href={CV} // Replace with the actual path to your CV
                download
                className="w-full inline-flex items-center justify-center px-3 py-2 mt-3 mr-2 bg-gray-800 text-white font-semibold rounded-md shadow-lg hover:bg-gray-700 transition-colors"
              >
                Download CV
                <ReplyIcon
                  style={{ transform: "rotate(-90deg)" }}
                  className="ml-2 border-l-4 border-gray-300 rounded-sm"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div className="mt-8" data-aos="fade-up">
      <h1 className="text-2xl font-bold mb-4 pt-6">Skills</h1>
      <p className="text-base mb-4 font-bold">
        Here are some of the skills I have developed over the years :
      </p>
      <div className="space-y-4">
        <div className="flex items-center">
          <span className="w-2/4 text-base mr-0 md:mr-6">
            Front-End Technologies (React, Vue, React Native)
          </span>
          <ProgressBar width={"90%"} />
        </div>
        <div className="flex items-center">
          <span className="w-2/4 text-base mr-0 md:mr-6">
            Backend Development (Node.js, Express, ASP.NET)
          </span>

          <ProgressBar width={"73%"} />
        </div>
        <div className="flex items-center">
          <span className="w-2/4 text-base mr-0 md:mr-6">
            Database Management (MySQL, MongoDB, SQL Server)
          </span>

          <ProgressBar width={"70%"} />
        </div>
        <div className="flex items-center">
          <span className="w-2/4 text-base mr-0 md:mr-6">
            GIS & Mapping Tools (Leaflet, QGIS, ArcGIS)
          </span>

          <ProgressBar width={"85%"} />
        </div>
        <div className="flex items-center">
          <span className="w-2/4 text-base mr-0 md:mr-6">
            Testing (Selenium)
          </span>

          <ProgressBar width={"75%"} />
        </div>
        <div className="flex items-center">
          <span className="w-2/4 text-base mr-0 md:mr-6">
            Other Tools (Git, CI/CD)
          </span>

          <ProgressBar width={"78%"} />
        </div>
      </div>
    </div>
  </section>
);

export default About;
